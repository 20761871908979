import React from 'react';
import { Navigate } from 'react-router-dom';
import PrivateLayout from './PrivateLayout';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { session, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  // Check for both session and local token
  const isAuthenticated = session?.access_token && localStorage.getItem('token');

  return isAuthenticated ? (
    <PrivateLayout>{children}</PrivateLayout>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;