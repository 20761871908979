import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { BookOpen, ExternalLink, Mic, LineChart } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const API_URL = 'https://compoundingai.in';
const LOCAL_API_URL = 'http://35.207.198.186';

const MessageSources = ({ sources, messageId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [hoveredTranscript, setHoveredTranscript] = useState(null);

  console.log('Received sources:', sources);

  const sourcesArray = React.useMemo(() => {
    if (!sources || typeof sources !== 'object') {
      console.log('Invalid sources:', sources);
      return [];
    }
    
    return Object.entries(sources).map(([key, value]) => ({
      id: key,
      ...value
    })).filter(Boolean);
  }, [sources]);

  useEffect(() => {
    const handleSourceClick = (e) => {
      const citation = e.target.closest(`.source-${messageId}-${e.target.closest('.source-citation')?.dataset.source}`);
      
      if (citation) {
        const sourceId = citation.dataset.source;
        const source = sources[sourceId];
        
        console.log('Citation clicked:', { sourceId, source, messageId });
        
        if (source) {
          if (source.type === 'slide') {
            window.open(`${API_URL}/${source.content}`, '_blank');
          } else if (source.type === 'concall') {
            const rect = citation.getBoundingClientRect();
            setTooltipPosition({
              top: rect.bottom + window.scrollY,
              left: rect.left + window.scrollX
            });
            setHoveredTranscript(source.content.replace(/^Text: Text: /, ''));
          }
        }
      } else if (!e.target.closest('.transcript-tooltip')) {
        setHoveredTranscript(null);
        setTooltipPosition(null);
      }
    };

    setHoveredTranscript(null);
    setTooltipPosition(null);

    document.addEventListener('click', handleSourceClick);
    return () => {
      document.removeEventListener('click', handleSourceClick);
      setHoveredTranscript(null);
      setTooltipPosition(null);
    };
  }, [sources, messageId]);

  const TranscriptTooltip = () => {
    if (!hoveredTranscript || !tooltipPosition) return null;

    return (
      <div
        className="fixed z-50 p-4 bg-gray-800 rounded-lg shadow-xl max-w-md max-h-60 overflow-y-auto transcript-tooltip border border-gray-600"
        style={{
          top: `${tooltipPosition.top + 10}px`,
          left: `${tooltipPosition.left}px`,
        }}
      >
        <div className="text-sm text-gray-200">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              p: ({node, ...props}) => <p className="mb-4" {...props} />,
              ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-4" {...props} />,
              ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-4" {...props} />,
              li: ({node, ...props}) => <li className="mb-2" {...props} />,
            }}
          >
            {hoveredTranscript}
          </ReactMarkdown>
        </div>
      </div>
    );
  };

  if (!sourcesArray || sourcesArray.length === 0) return null;

  const getSourceIcon = (type) => {
    switch (type) {
      case 'concall':
        return <Mic className="w-4 h-4" />;
      case 'slide':
        return <LineChart className="w-4 h-4" />;
      default:
        return <BookOpen className="w-4 h-4" />;
    }
  };

  const getImagePath = (content) => {
    if (!content) return '';
    
    if (content.startsWith('sources/')) {
      return content;
    }
    return `sources/${content}`;
  };

  const renderSourceContent = (source) => {
    const icon = getSourceIcon(source.type);
    const label = `${source.type === 'concall' ? 'Earnings Call' : 'Presentation'} ${source.quarter || ''} ${source.year || ''}`;

    if (source.type === 'slide') {
      console.log('Original content path:', source.content);
      const fullUrl = `${API_URL}/${source.content}`;
      
      return (
        <div className="relative group">
          <img
            src={fullUrl}
            alt={label}
            className="w-full h-32 object-cover rounded-lg hover:opacity-90 transition-opacity"
            onError={(e) => {
              console.error('Image load error for path:', source.content);
              console.error('Full URL that failed:', fullUrl);
              e.target.onerror = null;
            }}
          />
          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
            <a 
              href={fullUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-black bg-opacity-50 text-white px-2 py-1 rounded flex items-center gap-2 hover:bg-opacity-75"
            >
              <ExternalLink className="w-4 h-4" /> <span>Open image</span>
            </a>
          </div>
        </div>
      );
    }

    const transcriptContent = source.content.replace(/^Text: Text: /, '');

    return (
      <div className="flex items-center gap-2 text-sm text-gray-200">
        <span className="text-gray-400">{icon}</span>
        <span className="font-medium">{label}</span>
        <div className="text-xs text-gray-400 cursor-help group relative">
          <span>[Hover for transcript]</span>
          <div className="hidden group-hover:block absolute left-0 top-full mt-2 p-3 bg-gray-800 rounded-lg shadow-xl z-10 w-96 max-h-60 overflow-y-auto">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              components={{
                p: ({node, ...props}) => <p className="mb-4" {...props} />,
                ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-4" {...props} />,
                ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-4" {...props} />,
                li: ({node, ...props}) => <li className="mb-2" {...props} />,
              }}
            >
              {transcriptContent}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mt-4">
        <div className="flex items-center gap-2 mb-2">
          <BookOpen className="w-4 h-4 text-gray-400" />
          <span className="text-sm font-medium text-gray-400">Sources</span>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {sourcesArray.slice(0, 3).map((source) => (
            <div
              key={source.id}
              className="p-3 rounded-lg bg-gray-800 hover:bg-gray-700 transition-colors border border-gray-700"
            >
              {renderSourceContent(source)}
            </div>
          ))}
        </div>

        {/* View More Sources Modal */}
        {sourcesArray.length > 3 && (
          <button
            onClick={() => setIsDialogOpen(true)}
            className="mt-2 text-sm text-blue-400 hover:text-blue-300"
          >
            View {sourcesArray.length - 3} more sources
          </button>
        )}

        <Transition appear show={isDialogOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setIsDialogOpen(false)}
            open={isDialogOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4">
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-lg bg-gray-800 p-6 shadow-xl transition-all">
                  <Dialog.Title 
                    className="text-lg font-medium text-white mb-4"
                    tabIndex={0}
                  >
                    All Sources
                  </Dialog.Title>
                  <button
                    onClick={() => setIsDialogOpen(false)}
                    className="absolute top-2 right-2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    ✕
                  </button>
                  <div className="grid grid-cols-1 gap-4 max-h-[60vh] overflow-y-auto">
                    {sourcesArray.map((source) => (
                      <div
                        key={source.id}
                        className="p-3 rounded-lg bg-gray-700 hover:bg-gray-600 transition-colors"
                      >
                        {source.type === 'slide' ? (
                          <div className="relative group">
                            <img
                              src={`${API_URL}/${source.content}`}
                              alt={`${source.quarter} ${source.year}`}
                              className="w-full h-32 object-cover rounded-lg hover:opacity-90 transition-opacity"
                              onError={(e) => {
                                console.error('Image load error for path:', source.content);
                                e.target.onerror = null;
                                e.target.src = `${API_URL}/static/placeholder.png`;
                              }}
                            />
                            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                              <a 
                                href={`${API_URL}/${source.content}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-black bg-opacity-50 text-white px-2 py-1 rounded flex items-center gap-2 hover:bg-opacity-75"
                              >
                                <ExternalLink className="w-4 h-4" /> <span>Open image</span>
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2 text-sm text-gray-200">
                            <span className="text-gray-400">{getSourceIcon(source.type)}</span>
                            <span className="font-medium">
                              {`${source.type === 'concall' ? 'Earnings Call' : 'Presentation'} ${source.quarter || ''} ${source.year || ''}`}
                            </span>
                            <div className="text-xs text-gray-400 cursor-help group relative">
                              <span>[View transcript]</span>
                              <div className="hidden group-hover:block absolute left-0 top-full mt-2 p-3 bg-gray-800 rounded-lg shadow-xl z-10 w-96 max-h-60 overflow-y-auto">
                                <ReactMarkdown
                                  rehypePlugins={[rehypeRaw]}
                                  components={{
                                    p: ({node, ...props}) => <p className="mb-4" {...props} />,
                                    ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-4" {...props} />,
                                    ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-4" {...props} />,
                                    li: ({node, ...props}) => <li className="mb-2" {...props} />,
                                  }}
                                >
                                  {source.content.replace(/^Text: Text: /, '')}
                                </ReactMarkdown>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      <TranscriptTooltip />
    </>
  );
};

export default MessageSources;