import React from 'react';
import { MessageSquarePlus } from 'lucide-react';

const FollowUpQueries = ({ queries, onQueryClick }) => {
  if (!queries || queries.length === 0) return null;

  return (
    <div className="mt-4 border-t border-gray-700 pt-4">
      <div className="flex items-center gap-2 mb-2">
        <MessageSquarePlus className="w-4 h-4 text-gray-400" />
        <span className="text-sm font-medium text-gray-400">
          Follow-up questions
        </span>
      </div>
      <div className="flex flex-wrap gap-2">
        {queries.map((query, index) => (
          <button
            key={index}
            onClick={() => onQueryClick(query)}
            className="px-3 py-1.5 text-sm rounded-full bg-blue-900/20 text-blue-400 hover:bg-blue-900/30 transition-colors"
          >
            {query}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FollowUpQueries; 