import React, { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { ArrowUp } from 'lucide-react';

const MessageInput = ({ sendMessage, loading }) => {
  const [message, setMessage] = useState('');
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loading && message.trim()) {
      sendMessage(message);
      setMessage('');
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="relative flex items-center bg-gray-800 rounded-lg shadow-sm border border-gray-700"
    >
      <TextareaAutosize
        ref={inputRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
          }
        }}
        placeholder="Ask a question..."
        className="flex-1 max-h-32 p-3 bg-transparent resize-none focus:outline-none text-white placeholder-gray-400"
        maxRows={5}
      />
      <button
        type="submit"
        disabled={loading || !message.trim()}
        className="p-2 m-1 rounded-lg bg-blue-600 text-white disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 transition-colors"
      >
        <ArrowUp size={20} />
      </button>
    </form>
  );
};

export default MessageInput; 