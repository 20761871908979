import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Link,
  IconButton,
} from "@mui/material";
import {
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
} from "@mui/icons-material";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black",
        color: "white",
        py: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              compoundingAI
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              From ideas to buying stock within minutes powered by our AI search
              engine and insights dashboard.
            </Typography>
            <Box>
              <IconButton color="inherit" aria-label="Instagram">
                <InstagramIcon />
              </IconButton>
              <IconButton 
                color="inherit" 
                aria-label="Twitter"
                component="a"
                href="https://x.com/compoundingaiin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Solutions */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Solutions
            </Typography>
            <Box>
              <Link href="#" color="inherit" display="block" sx={{ mb: 1 }}>
                Search
              </Link>
              <Link href="#" color="inherit" display="block" sx={{ mb: 1 }}>
                Deep-Dive
              </Link>
              <Link href="#" color="inherit" display="block">
                FinAI Agent
              </Link>
            </Box>
          </Grid>

          {/* Contact */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: support@compoundingai.in
            </Typography>
          </Grid>
        </Grid>

        {/* Copyright */}
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://compoundingai.in/">
              CompoundingAI
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;