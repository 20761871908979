import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MessageInput from './chat/MessageInput';
import MessageSources from './chat/MessageSources';
import FollowUpQueries from './chat/FollowUpQueries';
import { useAuth } from '../context/AuthContext';

const API_URL = 'https://compoundingai.in';

const FinancialNews = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageAppeared, setMessageAppeared] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const messageEnd = useRef(null);
  let buffer = '';

  // Get access token from Supabase auth
  const { session } = useAuth();
  const accessToken = session?.access_token;

  useEffect(() => {
    const initSession = async () => {
      try {
        if (!accessToken) {
          console.error('No access token available');
          return;
        }

        console.log('Initializing chat session...');
        const response = await fetch('https://compoundingai.in/api/chat/session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Session creation failed:', errorData);
          return;
        }

        const data = await response.json();
        console.log('Session created:', data);
        setSessionId(data.session_id);
      } catch (error) {
        console.error('Error creating session:', error);
      }
    };

    if (accessToken) {
      initSession();
    }
  }, [accessToken]);

  useEffect(() => {
    messageEnd.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    return () => {
      buffer = '';
    };
  }, []);

  const formatMessage = (message) => {
    if (message.role === 'assistant' && typeof message.content === 'string') {
      const formattedContent = message.content.replace(
        /\[(\d+)\]/g,
        (match, num) => {
          const source = message.sources?.[num];
          if (!source) return match;
          
          const tooltipText = source.type === 'concall' 
            ? 'Click to see transcript'
            : 'Click to view presentation';
          
          const sourceLabel = `${source.type === 'concall' ? 'Earnings Call' : 'Presentation'} ${source.quarter || ''} ${source.year || ''}`;
          
          return `<span class="text-blue-400 cursor-pointer source-citation" 
            data-source="${num}" 
            data-message-id="${message.messageId}"
            data-source-type="${source.type}"
            data-source-content="${encodeURIComponent(JSON.stringify(source))}"
            title="${tooltipText} - ${sourceLabel}">[${num}]</span>`;
        }
      );
      return formattedContent;
    }
    return message.content;
  };

  const processSourceValue = (value) => {
    try {
      // If it's already an object, return it
      if (typeof value === 'object' && value !== null) {
        return value;
      }
      
      // If it's a string, try to parse it
      if (typeof value === 'string') {
        // Replace single quotes with double quotes for JSON parsing
        return JSON.parse(value.replace(/'/g, '"'));
      }
      
      console.log('Invalid source value:', value);
      return null;
    } catch (e) {
      console.error('Error processing source value:', e, 'Value:', value);
      return null;
    }
  };

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const sendMessage = async (message) => {
    if (loading || !sessionId || !accessToken) {
      console.error('Cannot send message:', { 
        loading, 
        sessionId: sessionId ? 'exists' : 'missing', 
        token: accessToken ? 'exists' : 'missing' 
      });
      return;
    }

    setLoading(true);
    setMessageAppeared(false);

    const newUserMessage = {
      content: message,
      messageId: Date.now().toString(),
      role: 'user',
      createdAt: new Date(),
    };

    setMessages(prev => [...prev, newUserMessage]);

    try {
      console.log('Sending message with session:', sessionId);
      const response = await fetch('https://compoundingai.in/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          session_id: sessionId,
          messages: [...messages, newUserMessage],
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Chat request failed:', errorData);
        throw new Error(errorData.detail || 'Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n').filter(line => line.trim());

        for (const line of lines) {
          try {
            // Append the new chunk to our buffer
            buffer += line;
            
            // Check if we have a complete JSON object
            if (buffer.startsWith('{') && buffer.endsWith('}') && isValidJSON(buffer)) {
              const data = JSON.parse(buffer);
              console.log('Received complete data:', data);
              
              const newAssistantMessage = {
                content: data.answer,
                messageId: Date.now().toString(),
                role: 'assistant',
                sources: data.citations || {},
                followupQueries: data.followup_queries || [],
                createdAt: new Date(),
              };
              
              setMessages(prev => [...prev, newAssistantMessage]);
              setMessageAppeared(true);
              
              // Clear buffer after successful processing
              buffer = '';
            } else {
              // If not a complete JSON object, continue accumulating
              console.log('Accumulated partial data, waiting for complete JSON');
            }
          } catch (error) {
            console.error('Error processing chunk:', error);
            // Don't clear buffer on error, as it might be incomplete JSON
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage = {
        content: "I apologize, but I encountered an error processing your request. Please try again.",
        messageId: Date.now().toString(),
        role: 'assistant',
        createdAt: new Date(),
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  // Add click handler for citations
  useEffect(() => {
    const handleCitationClick = (e) => {
      const citation = e.target.closest('.source-citation');
      if (!citation) return;

      try {
        const sourceType = citation.dataset.sourceType;
        const sourceContent = JSON.parse(decodeURIComponent(citation.dataset.sourceContent));

        if (sourceType === 'slide') {
          // Open slides in new tab
          window.open(`${API_URL}/${sourceContent.content}`, '_blank');
        } else if (sourceType === 'concall') {
          // Show transcript popup
          const rect = citation.getBoundingClientRect();
          const transcriptContent = sourceContent.content.replace(/^Text:\s*Text:\s*/, '');
          
          // Create popup element
          const popup = document.createElement('div');
          popup.className = 'fixed z-50 p-4 bg-gray-800 rounded-lg shadow-xl max-w-md max-h-60 overflow-y-auto transcript-tooltip border border-gray-600';
          popup.style.top = `${rect.bottom + window.scrollY + 10}px`;
          popup.style.left = `${rect.left + window.scrollX}px`;
          popup.innerHTML = `
            <div class="text-sm text-gray-200">
              ${transcriptContent}
            </div>
          `;
          
          // Remove existing popups
          document.querySelectorAll('.transcript-tooltip').forEach(el => el.remove());
          
          // Add new popup
          document.body.appendChild(popup);
          
          // Close popup when clicking outside
          const closePopup = (e) => {
            if (!popup.contains(e.target) && !citation.contains(e.target)) {
              popup.remove();
              document.removeEventListener('click', closePopup);
            }
          };
          
          setTimeout(() => {
            document.addEventListener('click', closePopup);
          }, 0);
        }
      } catch (error) {
        console.error('Error handling citation click:', error);
      }
    };

    document.addEventListener('click', handleCitationClick);
    return () => {
      document.removeEventListener('click', handleCitationClick);
      document.querySelectorAll('.transcript-tooltip').forEach(el => el.remove());
    };
  }, []);

  // Don't render anything until we have both session and token
  if (!accessToken || !sessionId) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-gray-900">
      <div className="border-b border-gray-700 p-4">
        <h1 className="text-xl font-semibold text-white">Financial Research Assistant</h1>
      </div>

      <div className="flex-1 overflow-auto p-4 space-y-6">
        {messages.map((message) => (
          <div
            key={message.messageId}
            className={`${
              message.role === 'user' 
                ? 'bg-blue-900/20' 
                : 'bg-gray-800'
            } p-4 rounded-lg shadow-lg`}
          >
            {message.role === 'user' ? (
              <div className="text-sm text-blue-200">
                {message.content}
              </div>
            ) : (
              <div className="text-sm text-gray-200">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    p: ({node, ...props}) => <p className="mb-4" {...props} />,
                    ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-4" {...props} />,
                    ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-4" {...props} />,
                    li: ({node, ...props}) => <li className="mb-2" {...props} />,
                  }}
                >
                  {formatMessage(message)}
                </ReactMarkdown>
              </div>
            )}
            
            {message.role === 'assistant' && message.sources && (
              <MessageSources sources={message.sources} />
            )}
            {message.role === 'assistant' && message.followupQueries && (
              <FollowUpQueries 
                queries={message.followupQueries}
                onQueryClick={(query) => sendMessage(query)}
              />
            )}
          </div>
        ))}
        {loading && !messageAppeared && (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-400"></div>
          </div>
        )}
        <div ref={messageEnd} />
      </div>

      <div className="border-t border-gray-700 p-4 bg-gray-800">
        <MessageInput 
          sendMessage={sendMessage}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FinancialNews;