import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from './context/AuthContext';
import Navbar from "./components/Nav/Navbar";
import Home from "./components/Home";
import Login from "./components/Login";
import Waitlist from "./components/Waitlist";
import Register from "./components/Register";
import SearchPage from "./components/SearchPage";
import Footer from "./components/Footer";
import PrivateRoute from "./components/PrivateRoute";
import FinancialNews from './components/FinancialNews';

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
  },
});

function App() {
  const location = useLocation();
  const isPrivateRoute = ['/search', '/chat'].includes(location.pathname);
  const isChatRoute = location.pathname === '/chat';

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          {!isPrivateRoute && !isChatRoute && <Navbar />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/search" element={<PrivateRoute><SearchPage /></PrivateRoute>} />
            <Route path="/chat" element={<PrivateRoute><FinancialNews /></PrivateRoute>} />
          </Routes>
          {!isPrivateRoute && !isChatRoute && <Footer />}
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
