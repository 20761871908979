import React from 'react';
import PrivateNavbar from './Nav/PrivateNavbar';
import { useAuth } from '../context/AuthContext';

const PrivateLayout = ({ children }) => {
  const { session } = useAuth();

  return (
    <div>
      <PrivateNavbar user={session?.user} />
      <div>{children}</div>
    </div>
  );
};

export default PrivateLayout;